import { t } from '../../generated/i18n';
import { IS_DEFAULT_CHANNEL, PRICE_FIELD } from '../../lib/config';
import { formatPrice } from '../../lib/format';
import { FadeIn } from '../common/transitions';

const format = (price) =>
  formatPrice(price.currency, price[PRICE_FIELD]?.amount ?? price.amount);

const OverviewLine = ({ className = '', desc, price }) => (
  <div className={`flex justify-between ${className}`}>
    <div>{desc}</div>
    <div>{price}</div>
  </div>
);

export const RemainingForFreeShipping = ({ className = '', checkout }) => {
  const free_shipping_cost_amount = t('free_shipping_cost_amount');

  const remaining = Math.max(
    free_shipping_cost_amount - checkout.subtotalPrice[PRICE_FIELD]?.amount,
    0,
  );

  const rate = 100 - (100 / free_shipping_cost_amount) * remaining;

  return (
    <FadeIn
      className={className}
      as='div'
      show={!(remaining <= 0 || remaining == free_shipping_cost_amount)}
    >
      <div className='flex justify-between'>
        <div>{t('free_delivery')}</div>
        <div className='font-bold'>
          {/* TODO translate */}
          {t('noch')} {formatPrice('CHF', remaining)}
        </div>
      </div>
      <div className='relative'>
        <div className='mt-4 h-[6px] w-full bg-black opacity-10'></div>
        <div
          className='absolute top-0 z-10 h-[6px] bg-black transition-all duration-300 ease-in-out'
          style={{ width: rate + '%' }}
        ></div>
      </div>
    </FadeIn>
  );
};

const TotalsSection = ({ checkout, className = '', subtotalOnly = false, review=false }) => {
  // if (!checkout) {
  //   return <></>;
  // }

  const discount = Math.round(
    checkout.subtotalPrice[PRICE_FIELD]?.amount +
      checkout.shippingPrice[PRICE_FIELD]?.amount -
      checkout.totalPrice[PRICE_FIELD]?.amount,
  ) || checkout.discount?.amount || 0;

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <OverviewLine
        desc={t('subtotal')}
        className={subtotalOnly ? 'font-bold' : ''}
        price={format(checkout.subtotalPrice)}
      />
      {!subtotalOnly && (
        <>
          <OverviewLine
            desc={t('shippingCosts')}
            price={format(checkout.shippingPrice)}
          />
          {discount > 0 ? (
            <OverviewLine
              className='text-green'
              desc={t('discount')}
              price={formatPrice(checkout.totalPrice.currency, -1 * discount)}
            />
          ) : null}
          <OverviewLine
            className='font-bold'
            desc={IS_DEFAULT_CHANNEL ? t('totalInclTax') : t('totalexcltax')}
            price={format(checkout.totalPrice)}
          />
          {!IS_DEFAULT_CHANNEL && review  ? (<>
            <OverviewLine
              className='font-bold border-t border-0.3 mt-4 pt-4'
              desc={t('tax')}
              price={formatPrice(checkout.totalPrice.currency, checkout.totalPrice.tax.amount)}
            />
            <OverviewLine
              className='font-bold'
              desc={t('totalInclTax')}
              price={formatPrice(checkout.totalPrice.currency, checkout.totalPrice.gross.amount)}
            />
          </>) : (
          <>
          </>)}
        </>
      )}
    </div>
  );
};

export default TotalsSection;
